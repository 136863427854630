import { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { UserRootState } from './state';
import { Order } from '@shared/order';

const selectFeature = (state: AppState): UserRootState => state.user.root;

export class UserRootSelectors {
  public static activeServiceID: MemoizedSelector<AppState, number> = createSelector(
    selectFeature,
    (state) => state?.activeServiceID
  );

  public static currentOrders: MemoizedSelector<AppState, Record<number, Order>> = createSelector(
    selectFeature,
    (state) => state.currentOrders
  );

  public static activeOrder: MemoizedSelector<AppState, Order> = createSelector(
    UserRootSelectors.currentOrders,
    UserRootSelectors.activeServiceID,
    (currentOrders, activeServiceID) => currentOrders[activeServiceID] || null
  );

  public static arePublicPromocodesAvailable: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => !!state?.arePublicPromocodesAvailable
  );

  public static arePublicPromocodesAvailableForMenu: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state) => !!state?.arePublicPromocodesAvailableForMenu
  );
}
