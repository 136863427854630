import { TransformDate, TransformDateFormat } from '@shared/transform-date';
import { Exclude, Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { Connection } from './connection';
import { isMobile } from 'is-mobile';
import { Gender } from '@shared/user';
import { DeviceInfo, DeviceInfoType } from '@shared/device-info';
import {UserType} from '@shared/user-type-modal';

export class RegisterRequest {
  @Expose()
  public email: string;

  @Expose()
  public password: string;

  @Expose()
  public phone: string;

  @Expose({ name: 'phone_code' })
  public phoneCode: string;

  @Expose({ name: 'is_lending' })
  public isLending: boolean;

  @Exclude()
  public firstName: string;

  @Exclude()
  public lastName: string;

  @Expose()
  public account_type: UserType;

  @Expose()
  @TransformDate(TransformDateFormat.SQL_DATE)
  public birthdate: DateTime;

  @Expose()
  public gender: Gender | null;

  @Expose({ name: 'gender_other' })
  public genderOther: string | null;

  @Expose()
  public connection: Connection;

  @Expose({ name: 'device_info' })
  @Type(() => DeviceInfo)
  public deviceInfo: DeviceInfo;

  @Expose()
  public get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  constructor(model: Partial<RegisterRequest>) {
    Object.assign(this, model);

    this.deviceInfo = model.deviceInfo || new DeviceInfo({
      type: (isMobile()) ? DeviceInfoType.MOBILE_WEB : DeviceInfoType.WEB
    });
  }
}
