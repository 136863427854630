import { LocalStorageService } from '@shared/local-storage';
import { Order } from '@shared/order';

export class UserRootState {
  public activeServiceID: number;
  public currentOrders: Record<number, Order>;
  public arePublicPromocodesAvailable: boolean;
  public arePublicPromocodesAvailableForMenu: boolean;

  constructor() {
    this.arePublicPromocodesAvailable = LocalStorageService.arePublicPromocodesAvailable.get();
    this.arePublicPromocodesAvailableForMenu = LocalStorageService.arePublicPromocodesAvailableForMenu.get();
    this.currentOrders = {};

    const hasDraft = LocalStorageService.orderDraftExpiration.get()?.diffNow().milliseconds > 0 ?? false;

    if (hasDraft) {
      const order = new Order(LocalStorageService.orderDraft.get());

      this.activeServiceID = order.serviceID;
      this.currentOrders[this.activeServiceID] = order;
    }
  }
}
