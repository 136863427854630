import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Order } from '@shared/order';
import { User } from '@shared/user';
import { Observable } from 'rxjs';
import { NavbarFacade } from './navbar.facade';
import {WebviewService} from '@shared/webview';
import {Router} from '@angular/router';
import {isCommercial} from '@shared/navbar/utils';

@Component({
  selector: 'navbar',
  templateUrl: 'navbar.html',
  styleUrls: ['navbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  public activeOrder$: Observable<Order>;
  public user$: Observable<User>;
  public isWebView: boolean = false;
  public isLocation$: Observable<boolean>;
  public isNewDesign$: Observable<boolean>;
  public isCommercial$: Observable<boolean>;
  public isRedirectToCity$: Observable<boolean>;
  public isNewServicesNav$: Observable<boolean>;
  public hasZipcode$: Observable<boolean>;
  constructor(private facade: NavbarFacade,public webviewService: WebviewService,public router:Router) {
    this.user$ = this.facade.user$;
    this.activeOrder$ = this.facade.activeOrder$;
    this.isWebView = this.webviewService.isWebview;
    this.isLocation$=this.facade.isLocation$;
    this.isNewDesign$=this.facade.isNewDesignNav$;
    this.isCommercial$=this.facade.isCommercial$;
    this.isRedirectToCity$=this.facade.isRedirectToCity$;
    this.isNewServicesNav$=this.facade.isNewServicesNav$;
    this.hasZipcode$=this.facade.hasZipcode$;
  }

  public menuTriggerClicked(): void {
    this.facade.openMenu();
  }

}
