import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { GeolocationViewActions } from './actions';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { UserAddressActions, UserService, UserZipActions, UserZipSelectors } from '@shared/user';
import { GeolocationService } from '@shared/geolocation/geolocation.service';
import { AppDialogService } from '@shared/dialog';
import { getSelectors } from '@ngrx/router-store';
import { isLocationAllowedOnURL } from '../utils';
import {TopLocationsActions} from '@shared/footer/store';
import {LocationType} from '@shared/location';
import {Router} from '@angular/router';

@Injectable()
export class GeolocationViewEffects {
  public tryPredictZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationViewActions.tryPredictZip),
      withLatestFrom(
        this.userService.profile$,
        this.store.select(UserZipSelectors.zip),
        this.store.select(getSelectors().selectUrl)
      ),
      filter(([_, user, zip, url]) => !user?.addresses?.length && !zip && isLocationAllowedOnURL(url)),
      tap(() => this.store.dispatch(GeolocationViewActions.predictZipStarted())),
      exhaustMap(() => this.geolocationService.geolocate()
        .pipe(
          map((place) => {
            const parsedZipCode = place?.zipCode;

            return (!!parsedZipCode)
              ? GeolocationViewActions.predictZipSuccess({ zipCode: parsedZipCode })
              : GeolocationViewActions.predictZipFailure();
          })
        )
      )
    )
  );

  public tryUpdateZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationViewActions.tryUpdateZip),
      withLatestFrom(
        this.store.select(UserZipSelectors.zip),
        this.userService.profile$,
        this.store.select(getSelectors().selectUrl)
      ),
      map(([{ dialogID, zipCode,isRedirectToCity }, userZip, profile, url]) => {
        if(isRedirectToCity){
          return UserZipActions.checkZip({
            zipCode,
            onSuccess: (zip) => {
              this.store.dispatch(UserZipActions.setZip({ zip }));
              this.dialogService.closeByID(dialogID);
              let code =zip?.getLocationOfType(LocationType?.CITY)?.code;
              if (url.startsWith('/services')) {
                this.store.dispatch(UserAddressActions.upsert({
                  request: { zipCode, isActive: true },
                  onSuccess: () => {
                    if (code) {
                      this.router.navigate([`/services/city/${code}`]);
                    }
                  }
                }))
              }
            }
          });
        } else if (profile) {
          return UserAddressActions.upsert({
            request: { zipCode, isActive: true },
            onSuccess: () => {
              this.dialogService.closeByID(dialogID);
              this.store.dispatch(GeolocationViewActions.clearPredictedZip());
              this.store.dispatch(TopLocationsActions.loadLocations());
            }
          });
        } else {
          return UserZipActions.checkZip({
            zipCode,
            onSuccess: (zip) => {
              this.store.dispatch(UserZipActions.setZip({ zip }));
              this.dialogService.closeByID(dialogID);
              this.store.dispatch(GeolocationViewActions.clearPredictedZip());
                this.store.dispatch(TopLocationsActions.loadLocations());

            }
          });
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private geolocationService: GeolocationService,
    private dialogService: AppDialogService,
    public router: Router,
  ) { }
}
