<div *ngVar="'SHARED.NAVBAR.' as translations" class="navbar" [class.navbar-zip]="(hasZipcode$ | async)">
  <content *ngVar="user$ | async as user" size="large" class="navbar-content">
    <ng-container *ngVar="isRedirectToCity$ |async as isRedirectToCity">
    <ng-container *ngVar="isLocation$ |async as isLocation">
    <ng-container *ngVar="isNewDesign$ |async as isNewDesign">
    <button *ngIf="!isNewDesign && (!isWebView || (isWebView && user))" data-test="menu-trigger" (click)="menuTriggerClicked()" class="button navbar-menu-trigger">
      <icon type="menu" [color]="isNewDesign ? 'purple' : !isLocation ? 'purple' : 'white'"></icon>
    </button>
      <button *ngIf="isNewDesign && (!isWebView || (isWebView && user))" data-test="menu-trigger" (click)="menuTriggerClicked()" class="button navbar-menu-trigger hidden-mobile">
        <icon type="menu" [color]="isNewDesign ? 'purple' : !isLocation ? 'purple' : 'white'"></icon>
      </button>
      <logo *ngIf="!isLocation || isNewDesign" variant='primary' class='navbar-mobile-logo' [class.navbar-mobile-logo-new]="isNewDesign"></logo>
    <div class="navbar-nav">
      <div *ngIf="(isCommercial$ | async) || (isNewServicesNav$ | async) || (isLocation$ | async)" class="navbar-nav-item navbar-geolocation">
        <geolocation-view [isRedirectToCity]="isRedirectToCity"></geolocation-view>
      </div>
      <div class="navbar-nav-item navbar-geolocation">
        <geolocation-view *ngIf="!isNewDesign" [isRedirectToCity]="isRedirectToCity"></geolocation-view>
      </div>
      <a routerLink="/profile" *ngIf="user" class="navbar-user navbar-nav-item">
        <div class="navbar-user-avatar">
          <avatar [src]="user.avatarLink"></avatar>
        </div>
        <span class="navbar-user-name">{{ user?.nameWithInitials }}</span>
      </a>
      <div *ngIf="!user" class="navbar-nav-item navbar-nav-item-auth">
        <app-link routerLink="/login" [color]="isNewDesign ? 'primary' :'purple'">
          <icon type="profile" color="default"></icon>
          {{ translations + 'LINK_LOGIN' | translate }}
        </app-link>
      </div>
      <ng-container *ngVar="activeOrder$ | async as activeOrder">
        <div *ngIf="!user" class="navbar-nav-item navbar-nav-item-auth">
          <a *ngIf="activeOrder?.hasItems; else noItems" routerLink="/register">
            <app-button variant="default" size="smaller">
              {{ translations + 'LINK_SIGNUP' | translate }}
            </app-button>
          </a>
          <ng-template #noItems>
            <a routerLink="/register">
              <app-button [variant]="isNewDesign ? 'purple' : 'primary'" size="smaller" customClass="flare">
                {{ translations + 'LINK_SIGNUP' | translate }}
              </app-button>
            </a>
          </ng-template>
        </div>
        <div *ngIf="activeOrder?.hasItems" class="navbar-nav-item">
          <a [routerLink]="'/services/' + activeOrder.serviceCode" class="button navbar-bag">
            <icon type="shopping-bag" color="white"></icon>
            {{ (activeOrder.items | itemsCount).toString() }}
          </a>
        </div>
      </ng-container>
    </div>
    <div class="navbar-geolocation navbar-geolocation-mobile" *ngIf="!isNewDesign">
      <geolocation-view></geolocation-view>
    </div>
      <button *ngIf="isNewDesign && (!isWebView || (isWebView && user))" data-test="menu-trigger" (click)="menuTriggerClicked()" class="button navbar-menu-trigger visible-mobile">
        <icon type="menu" [color]="isNewDesign ? 'purple' :'white'"></icon>
      </button>
    <div class="navbar-nav-item-zip navbar-geolocation navbar-geolocation-mobile navbar-geolocation-mobile-zip" *ngIf="(hasZipcode$ | async)">
      <geolocation-view></geolocation-view>
    </div>
    </ng-container>
    </ng-container>
    </ng-container>
  </content>
</div>
